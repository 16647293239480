@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Bold.ttf");
    font-style: normal;
    font-weight: 700;
}