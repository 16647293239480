header{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 25px 0;
    z-index: 2;
    background-color: $gray_01;

    @include media('<phone_max'){
        padding: 20px 0;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            z-index: 2;
            opacity: 0;
            transition: opacity 0s ease 0;
    
            .active &{
                transition: opacity 0s ease .8s;
                opacity: 1;
            }
        }
    }
    
    .cont{
        max-width: 1570px;
    }

    .content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    button{
        border: none;
        background-color: transparent;
    }

    .logo{
        position: relative;
        z-index: 2;

        .dark{
            transition: all 1s ease 0s;
            
            @include media('<phone_max'){
                position: relative;

                .active & {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: -1;
                    visibility: hidden;
                }
            }
        }

        .white{
            display:  none;
            transition: all 1s ease 0s;

            @include media('<phone_max'){
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                opacity: 0;
                z-index: -1;
                visibility: hidden;
            }

            .active & {
                position: relative;
                opacity: 1;
                z-index: 1;
                visibility: visible;
            }
        }
    }

    .overflow{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(7px);
        transition: all .5s ease 0s;
        visibility: hidden;
        opacity: 0;
        z-index: -1;

        .active &{
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
    
    .control{
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    
        @include media('<desktop'){
            position: relative;
        }
    }
    
    .arrow{
        position: relative;
        width: 20px;
        height: 9px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-left: 10px;
        transition: all .3s ease 0s;
        
        @include media('<desktop'){
            width: 30px;
            height: 15px;
            margin-left: 30px;

            &:hover{
    
                svg{
                    fill: $yellow;
                }
            }
        }
        
        @include media('<phone_max'){
            position: absolute;
            right: -45px;
            margin: 0;
        }

        svg{
            width: 8px;
            height: 5px;
            fill: $black;
            transition: all .3s ease 0s;

            @include media('<desktop'){
                fill: $white;
                width: 15px;
                height: 9px;
                z-index: -1;
            } 
        }
    }
    
    .menu-wrap{
        display: flex;
        align-items: center;

        @include media('<desktop'){
            position: fixed;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
            overflow: auto;
            top: 0;
            right: -100%;
            background-color: $black;
            transition: all .7s ease 0s;
            z-index: 1;
            padding: 80px 65px;
        }

        @include media('<tablet'){
            padding: 100px 30px;
        }

        @include media('<phone_max'){
            width: 100%;
        }
      
        .active & {
            
            @include media('<desktop'){
                right: 0;
            }
        }
    }
    
    .menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        margin: 0;
    
        @include media('<desktop'){
            flex-direction: column;
            align-items: flex-start;
            order: 2;
        }

        @include media('<phone_max'){
            align-items: center;
        }
    }
    
    .menu-item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;
    
        @include media('>=desktop') { 

            &:hover{

                .sub-menu{
                    transform: translate(0, 0);
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
    
                .arrow{
    
                    svg{
                        transform: rotate3d(1, 0, 0, 180deg);
                        fill: $yellow;
                    }
                }
    
                .link{
                    color: $yellow;
    
                    &::before{
                        width: 100%;
                    }
                }
            }
        }

        @include media('<widescreen'){
            margin: 0 10px;
        }
    
        @include media('<desktop'){
            flex-direction: column;
            margin: 0;
            margin-bottom: 20px;
            align-items: flex-start;
        }

        @include media('<phone_max'){
            align-items: center;
        }
    }
    
    .menu-item_active {
    
        @include media('<desktop'){

            .sub-menu{
                max-height: 500px;
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: all .7s ease 0s;
                text-align: center;
                margin: 30px 0 0 20px;
            }
    
            .arrow{

                svg{
                    transform: rotate3d(1, 0, 0, 180deg);
                    fill: $yellow;
                }
            }
        }

        @include media('<phone_max'){

            .sub-menu{
                margin: 20px 0 0 0;
            }
        }
    }

        
    .link{
        transition: all .3s ease 0s;
        font-size: 16px;
        font-weight: 500;
    
        @include media('>=desktop'){
            
            &::before{
                content:'';
                position: absolute;
                left: 0;
                bottom: -6px;
                width: 0;
                height: 3px;
                background-color: $yellow;
                transition: all .3s ease 0s;
            }
        }

        @include media('<desktop'){
            color: $white;
            font-size: 25px;

            &:hover{
                color: $yellow;
            }
        }
    }
    
    .sub-menu{
        position: relative;
        list-style: none;
        padding: 15px 0;
        margin: 0;
    
        @include media('>=desktop'){
            position: absolute;
            top: 100%;
            left: -15px;
            min-width: 190px;
            background-color: $gray_01;
            padding: 15px;
            border-radius: 10px;
            transition: all .3s ease 0s;
            transform: translate(0, 10px);
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
        }
    
        @include media('<desktop'){
            max-height: 0;
            min-width: 200px;
            visibility: hidden;
            transition: all .7s ease 0s;
            opacity: 0;
            pointer-events: none;
            text-align: center;
            padding: 0;
        }
            
        li{
            &::after{
                display: none;
            }
        }
    }
    
    .sub-item{
        padding: 5px 0;

        @include media('<desktop'){
            text-align: left;
        }

        @include media('<phone_max'){
            text-align: center;            
        }
    
        a{
            font-size: 15px;
            transition: all .2s ease 0s;
            
            @include media('<desktop'){
                font-size: 18px;
                color: $white;
            }
    
            &:hover{
                color: $yellow;
            }
        }
    }
    
    .search-btn, .account-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        svg{
            transition: all .3s ease 0s;
        }
    }

    .search{
        margin: 0 20px;

        @include media('<widescreen'){
            margin: 0 10px;
        }

        @include media('<desktop'){
           order: 3;
           margin: 50px 0 0 0;
        }
    }
    
    .search-btn {

        @include media('<desktop'){
            background-color: $yellow;
            width: 48px;
            height: 48px;
            border-radius: 9px;
        }

        svg{
            width: 18px;
            height: 18px;
        }

        &:hover{

            svg{
                fill: $yellow;

                @include media('<desktop'){
                    fill: $white;
                }
            }
        }
    }

    .account{
        margin-left: 20px;

        @include media('<widescreen'){
            margin-left: 10px;
        }

        @include media('<desktop'){
            order: 1;
            margin: 0 0 50px 0;
        }
    }

    .account-btn{

        &:hover{

            svg{
                fill: $yellow;
            }
        }

        svg{
            width: 22px;
            height: 22px;

            @include media('<desktop'){
                fill: $white;
                width: 47px;
                height: 47px;
            }
        }
    }
}


/* ---------------burger */

.burger{
    display: none;
    position: relative;
    width: 30px;
    height: 20px;
    z-index: 2;

    @include media('<desktop'){
        display: block;
    }

    span{
        position: absolute;
        top: 8px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $yellow;
        border-radius: 10px;
        transition: all .7s ease 0s;
        z-index: 2;
    }
    
    &::before, &::after{
        content:'';
        position: absolute;
        height: 4px;
        width: 100%;
        background-color: $black;
        border-radius: 10px;
        transition: all .5s ease 0s;
        z-index: 1;
    }

    &::before{
        top: 0;
        width: 50%;
        left: 0;
    }

    &::after{
        bottom: 0;
        width: 50%;
        right: 0;
    }

    .active &{
    
        span{
            transform: rotate(-45deg);
            top: 9px;
        }

        &::before, &::after{
            transform: rotate(45deg);
            background-color: $white;
        }
        
        &::before{
            top: 4px;
            left: 3px;
        }

        &::after{
            bottom: 2px;
            right: 2px;
        }
    }
}