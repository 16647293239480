// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
    font-family: $headings-font-family;
    font-weight: 600;
    color: $black;
    margin: 0;
}

h1,
.h1 {
    @include adaptiv-vaule("font-size", 36, 25, 1);
    line-height: 54 / 36 * 100%;
}

h2,
.h2 {
    @include adaptiv-vaule("font-size", 32, 20, 1);
    line-height: 48 / 32 * 100%;
}

h3,
.h3 {
    @include adaptiv-vaule("font-size", 20, 18, 1);
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

p {
    color: $black;
    margin: 0;
    font-size: 20px;
    line-height: 30 / 20 * 100%;

    @include media ("<tablet") {
        font-size: 16px;
    }
}

a {
    color: $base-link-color;
    text-decoration: none;
    transition: color .3s ease;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}